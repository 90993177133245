<template>
  <div>
    <b-row v-show="!isMovil" >
      <b-col
        v-for="(stat, i) in filteredStats"
        :key="i"
        cols="12"
        :lg="user && user.fk_role === 2 ? 2 : 3"
        :md="user && user.fk_role === 2 ? 2 : 3"
        :sm="user && user.fk_role === 2 ? 4 : 3"
      >
        <b-card
          no-body
          style="cursor: pointer"
          @click="filterByEstado(stat)"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ stat.value }}
              </h2>
              <span>{{ stat.label }}</span>
            </div>
            <b-avatar
              :style="stat.style"
              size="45"
            >
              <feather-icon
                size="21"
                :icon="stat.icon"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <TrabajosTable
      :estados="statistics"
      :estado-to-filter="filterEstado"
      @refetchStats="refetch"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BAvatar,
  BCardText,
} from 'bootstrap-vue'
import TrabajosTable from '@/views/work/trabajo/TrabajosTable.vue'
import trabajoStoreModule from '@/views/work/trabajo/trabajoStoreModule'
import useTrabajoList from '@/views/work/trabajo/useTrabajoList'
import { isMobile } from 'mobile-device-detect'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

export default {
  name: 'PartesTrabajo',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAvatar,
    BCardText,
    TrabajosTable,
  },
  data() {
    return {
      filterEstado: null,
      isMovil: isMobile,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    filteredStats() {
      const allowedEstadosForOperario = [
        'incidencia',
        'en_revision',
        'iniciado',
        'asignado',
        'cancelado',
        'rehacer',
      ]

      if (this.user.fk_role === 2) {
        return this.statistics?.filter(item => allowedEstadosForOperario.includes(item.key))
      }

      return this.statistics
    },
  },
  methods: {
    filterByEstado(stat) {
      this.filterEstado = stat.key
    },
  },
  setup() {
    const TRABAJO_APP_STORE_MODULE_NAME = 'app-trabajo'

    // Register module
    if (!store.hasModule(TRABAJO_APP_STORE_MODULE_NAME)) store.registerModule(TRABAJO_APP_STORE_MODULE_NAME, trabajoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRABAJO_APP_STORE_MODULE_NAME)) store.unregisterModule(TRABAJO_APP_STORE_MODULE_NAME)
    })

    const { getTrabajoStatistics, filterEstado, filterData } = useTrabajoList()

    const statistics = ref(null)

    const refetch = () => {
      getTrabajoStatistics().then(values => {
        statistics.value = values
      })
    }

    getTrabajoStatistics().then(values => {
      statistics.value = values
    })

    return {
      statistics,
      refetch,
    }
  },
}
</script>
