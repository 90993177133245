<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-toggle.collapse-1
        variant="outline-primary"
        class="mb-2"
      >
        Mostrar filtros
      </b-button>
    </div>
    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <b-card>
        <b-row>
          <!-- Inicio Contrato -->
          <b-col
            cols="12"
            lg="2"
            md="4"
            sm="6"
          >
            <label class="mt-1">Fecha inicio </label>
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="20"
              />
              <flat-pickr
                v-model="filterFechaInicio"
                class="form-control"
                placeholder="dd-mm-yyyy"
                :config="{
                  maxDate: filterFechaFin,
                  format: 'd-m-Y',
                  altFormat: 'd-m-Y',
                  allowInput: true,
                  altInput: true,
                  locale: Spanish
                }"
              />
            </div>
          </b-col>

          <!-- Fin contrato -->
          <b-col
            cols="12"
            lg="2"
            md="4"
            sm="6"
          >
            <label class="mt-1">Fecha fin</label>
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="20"
              />
              <flat-pickr
                v-model="filterFechaFin"
                class="form-control"
                placeholder="dd-mm-yyyy"
                :config="{
                  minDate: filterFechaInicio,
                  format: 'd-m-Y',
                  altFormat: 'd-m-Y',
                  allowInput: true,
                  altInput: true,
                  locale: Spanish
                }"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            lg="2"
            md="4"
            sm="6"
          >
            <b-form-group
              label="Zona"
              label-for="zona"
              class="mt-1"
            >
              <v-select
                v-model="filterZona"
                :options="zonasList"
                label="nombre"
                placeholder="Seleccione"
                autofocus
                :clearable="false"
                :reduce="(val) => val.id"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="2"
            md="4"
            sm="6"
          >
            <b-form-group
              label="Estados"
              label-for="estados"
              class="mt-1"
            >
              <v-select
                v-model="filterEstado"
                :options="filteredEstados || []"
                label="label"
                placeholder="Seleccione"
                autofocus
                :clearable="false"
                :reduce="(val) => val.key"
              />
            </b-form-group>
          </b-col>

          <!-- Filtrar -->
          <b-col
            cols="12"
            lg="2"
            md="3"
            sm="6"
            class="pt-2 mt-1"
          >
            <b-button
              variant="outline-primary"
              block
              @click="filterData"
            >
              Filtrar
              <feather-icon icon="FilterIcon" />
            </b-button>
          </b-col>
          <b-col
            cols="12"
            lg="2"
            md="3"
            sm="6"
            class="pt-2 mt-1"
          >
            <b-button
              variant="outline-danger"
              block
              @click="clearFilter"
            >
              Limpiar
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar"
              />
              <b-button
                v-if="user.fk_role !== 2"
                variant="primary"
                @click="editTrabajo('new')"
              >
                <span class="text-nowrap">Crear parte de trabajo</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="trabajoTable"
        ref="refTrabajoListTable"
        class="position-relative"
        :items="fetchTrabajo"
        :filter="filter"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :busy="!fetchTrabajo"
        :current-page="currentPage"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :tbody-tr-class="rowClass"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle"
              small
            />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>
        <!-- Encomienda -->
        <template #cell(encomienda)="data">
          <div v-if="data.item.encomienda">
            {{ data.item.encomienda.nombre }}
          </div>
          <div v-else>
            -
          </div>
        </template>

        <!-- Status -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :style="estadoColor(data.item.estado)"
          >{{
            estadoLabel(data.item.estado)
          }}</b-badge>
        </template>

        <!-- fecha_del_parte -->
        <template #cell(fecha_del_parte)="data">
          <div v-if="data.item.fecha_del_parte">
            {{ convertDate(data.item.fecha_del_parte) }}
          </div>
          <div v-else>
            -
          </div>
        </template>

        <!-- fecha de inicio -->
        <template #cell(fecha_de_inicio)="data">
          <div v-if="data.item.fecha_de_inicio">
            {{ convertDate(data.item.fecha_de_inicio) }}
          </div>
          <div v-else>
            -
          </div>
        </template>

        <!-- fecha de fin -->
        <template #cell(fecha_de_fin)="data">
          <div v-if="data.item.fecha_de_fin">
            {{ convertDate(data.item.fecha_de_fin) }}
          </div>
          <div v-else>
            -
          </div>
        </template>

        <!-- jefe equipo -->
        <template #cell(jefe_equipo)="data">
          <div v-if="data.item.jefe_equipo">
            {{ data.item.jefe_equipo.name }}
          </div>
          <div v-else>
            -
          </div>
        </template>

        <!-- duration -->
        <template #cell(duration)="data">
          <div v-if="data.item.fecha_de_inicio && data.item.fecha_de_fin">
            {{
              calculateDuration(
                data.item.fecha_de_inicio,
                data.item.fecha_de_fin
              )
            }}
            dias
          </div>
          <div>-</div>
        </template>

        <!-- duration -->
        <template #cell(peticionario)="data">
          {{ peticionarioLabel(data.item.peticionario) }}</template>

        <!-- Actions -->
        <template #cell(actions)="data">
          <b-row align-h="start">
            <!--Editar-->
            <div
              v-if="
                data.item.estado != 'cancelado' &&
                  data.item.estado != 'terminado'
              "
            >
              <b-button
                :id="`editar-${data.item.id}`"
                class="btn-icon"
                style="margin-right: 5px"
                variant="success"
                @click="editTrabajo(data.item.id)"
              ><feather-icon icon="EditIcon" /></b-button>
              <b-tooltip
                :target="`editar-${data.item.id}`"
                triggers="hover"
                placement="bottom"
                boundary="window"
              >
                <b>Editar</b>
              </b-tooltip>
            </div>

            <!--Estado-->
            <b-button
              :id="`estado-${data.item.id}`"
              class="btn-icon"
              style="margin-right: 5px"
              variant="primary"
              @click="openModalUpdateEstado(data)"
            ><feather-icon icon="SettingsIcon" /></b-button>
            <b-tooltip
              :target="`estado-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Actualizar estado</b>
            </b-tooltip>

            <!--Chat-->
            <div v-if="data.item.estado != 'No asignado' && data.item.estado != 'cancelado' && data.item.estado != 'terminado'">
              <b-button
                :id="`notify-${data.item.id}`"
                class="btn-icon"
                variant="info"
                style="margin-right: 5px"
                @click="notifyAssigned(data.item)"
              ><feather-icon icon="MessageSquareIcon" /></b-button>
              <b-tooltip
                :target="`notify-${data.item.id}`"
                triggers="hover"
                placement="bottom"
                boundary="window"
              >
                <b>Notificar en WhatsApp</b>
              </b-tooltip>
            </div>

            <!--Delete OCULTADO A PETICIÓN DEL CLIENTE-->
            <!--<div v-if="data.item.estado == 'no_asignado' && user.fk_role == 1">
              <b-button
                :id="`delete-${data.item.id}`"
                class="btn-icon"
                style="margin-right: 5px"
                variant="danger"
                @click="
                  selectedItem = data.item;
                  deleteModal = true;
                "
              ><feather-icon icon="Trash2Icon" /></b-button>
              <b-tooltip
                :target="`delete-${data.item.id}`"
                triggers="hover"
                placement="bottom"
                boundary="window"
              >
                <b>Apagar</b>
              </b-tooltip>
            </div>-->
          </b-row>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          />
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTrabajo"
              :per-page="perPage"
              aria-controls="trabajoTable"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template v-slot:prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template v-slot:next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      v-model="openEstadoModal"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-footer
      title="Cambiar estado"
    >
      <b-row>
        <b-col
          v-for="(status, i) in filteredEstados"
          :key="i"
          cols="12"
          lg="4"
          md="4"
          sm="6"
          class="mt-1"
        >
          <b-button
            block
            :variant="
              status.key == selectedEstado ? 'primary' : 'outline-primary'
            "
            @click="selectedEstado = status.key"
          >
            <b-avatar
              class="mb-1"
              size="45"
              :style="status.style"
            >
              <feather-icon
                size="21"
                :icon="status.icon"
              />
            </b-avatar>
            <div class="truncate">
              <span>{{ status.label }}</span>
            </div>
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="showErrorEstado"
        class="mx-0 mt-4"
      >
        <b-col>
          <p
            class="red-text"
            style="color: red"
          >
            {{ this.showErrorEstado }}
          </p>
          <p
            v-for="field in missingFields"
            :key="field"
          >
            {{ field }}
          </p>
        </b-col>
      </b-row>

      <b-row
        class="mx-0 mt-4"
        align-h="end"
      >
        <b-button
          variant="outline-primary"
          @click="
            openEstadoModal = false;
            selectedEstado = null;
            showErrorEstado = '';
          "
        >Cancelar</b-button>
        <b-button
          variant="primary"
          class="ml-1"
          @click="updateEstado()"
        >Guardar</b-button>
      </b-row>
    </b-modal>

    <ConfirmationModal
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el parte de trabajo"
      title="¿Está seguro de que desea eliminar el parte de trabajo?"
      @action="deleteTrabajo(selectedItem.id)"
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCollapse,
  VBToggle,
  BButton,
  BPagination,
  BFormInput,
  BSpinner,
  BAvatar,
  BCardText,
  BFormGroup,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import trabajoStoreModule from '@/views/work/trabajo/trabajoStoreModule'
import useTrabajoList from '@/views/work/trabajo/useTrabajoList'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es'
import useZonasList from '@/views/work/zonas/useZonasList'
import moment from "moment";
import zonaStoreModule from '@/views/work/zonas/zonaStoreModule'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  name: 'TrabajosTable',
  components: {
    vSelect,
    BTable,
    BBadge,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BCardTitle,
    BCollapse,
    BButton,
    BPagination,
    BFormInput,
    BSpinner,
    BAvatar,
    BCardText,
    BFormGroup,
    BTooltip,
    flatPickr,
    ConfirmationModal,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: ['estados', 'estadoToFilter'],
  data() {
    return {
      filter: null,
      sortBy: '',
      selectedItem: {},
      openEstadoModal: false,
      selectedEstado: null,
      Spanish,
      deleteModal: false,
      showErrorEstado: '',
      missingFields: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    filteredEstados() {
      const allowedEstadosForOperario = [
        'incidencia',
        'en_revision',
        'iniciado',
        'cancelado',
        'asignado',
        'rehacer',
      ]
      if (this.user.fk_role === 2) {
        return this.estados?.filter(item => allowedEstadosForOperario.includes(item.key))
      }

      return this.estados
    },
  },
  watch: {
    estadoToFilter(after, before) {
      this.filterEstado = after
      this.filterData()
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.is_urgent === true) return 'table-danger'
    },
    notifyAssigned(item) {
      const phoneNumber = `34${item.jefe_equipo.phone_number}`
      const name = `${item.jefe_equipo.name}`
      const fechaParte = moment(item.fecha_del_parte).format('DD-MM-YYYY')
      const msg = `Hola ${name}. Tienes un nuevo parte asignado.%0A%0A*Parte Nº*: ${item.codigo}%0A%0A*Fecha de inicio*: ${fechaParte}%0A%0A*Zona:* ${item.zona.nombre}%0A%0A*Trabajo a realizar:*%0A${item.trabajo_a_realizar}`
      const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${msg}`
      window.open(url)
    },
    getTime(date) {
      return date.split('T')[1].split('.')[0].substring(0, 5)
    },
    openModalUpdateEstado(data) {
      this.openEstadoModal = true
      this.selectedItem = data.item
      this.selectedEstado = data.item.estado
    },
    async updateEstado() {
      if (this.selectedEstado === 'iniciado') {
        // if (this.selectedItem.materiales.length < 1) {
        //   this.showErrorEstado = "Faltan campos por rellenar!";
        //   this.missingFields.push("Materiales");
        // } else {
        if (!this.selectedItem.fecha_de_inicio) {
          const temp_data = new Date(Date.now())
          const año = temp_data.getFullYear()
          const mes = String(temp_data.getMonth() + 1).padStart(2, '0')
          const dia = String(temp_data.getDate()).padStart(2, '0')
          const fechaFormateada = `${año}-${mes}-${dia}`
          this.selectedItem.fecha_de_inicio = fechaFormateada
        }

        this.selectedItem.estado = this.selectedEstado
        this.selectedItem.encomienda = this.selectedItem.encomienda?.id ?? null
        this.selectedItem.jefe_equipo = this.selectedItem.jefe_equipo?.id ?? null
        this.selectedItem.task = JSON.parse(this.selectedItem.task)?.id ?? this.selectedItem.task

        await this.$store.dispatch('app-trabajo/addTrabajo', this.selectedItem)
        this.selectedEstado = null
        this.openEstadoModal = false
        this.showErrorEstado = ''
        // }
      } else if (this.selectedEstado === 'no_asignado') {
        this.selectedItem.estado = this.selectedEstado
        this.selectedItem.encomienda = this.selectedItem.encomienda?.id ?? null
        this.selectedItem.task = JSON.parse(this.selectedItem.task)?.id ?? this.selectedItem.task
        this.selectedItem.jefe_equipo = this.selectedItem.jefe_equipo?.id ?? null
        this.selectedItem.asignado_a = null

        await this.$store.dispatch('app-trabajo/addTrabajo', this.selectedItem)
        this.selectedEstado = null
        this.openEstadoModal = false
        this.showErrorEstado = ''
      } else if (this.selectedEstado === 'en_revision') {
        if (
          !this.selectedItem.fecha_de_fin
          || this.selectedItem.materiales.length < 1
        ) {
          this.showErrorEstado = 'Faltan campos por rellenar!'
          if (!this.selectedItem.fecha_de_fin) this.missingFields.push('Fecha de Fin')
          if (this.selectedEstado.materiales.length < 1) this.missingFields.push('Materiales')
        } else {
          this.selectedItem.estado = this.selectedEstado
          await this.$store.dispatch('app-trabajo/updateEstado', {
            id: this.selectedItem.id,
            estado: this.selectedEstado,
          })
          this.selectedEstado = null
          this.openEstadoModal = false
          this.showErrorEstado = ''
        }
      } else if (this.selectedEstado === 'rehacer') {
        if (
          this.selectedItem.materiales.length < 1
          || !this.selectedItem.rehacer
        ) {
          this.showErrorEstado = 'Faltan campos por rellenar!'

          if (this.selectedItem.materiales.length < 1) this.missingFields.push('Materiales')
          if (!this.selectedItem.rehacer) {
            this.missingFields.push('Trabajos a rehacer')
          }
        } else {
          this.selectedItem.estado = this.selectedEstado
          this.selectedItem.encomienda = this.selectedItem.encomienda?.id ?? null
          this.selectedItem.task = JSON.parse(this.selectedItem.task)?.id ?? this.selectedItem.task
          this.selectedItem.jefe_equipo = this.selectedItem.jefe_equipo?.id ?? null
          this.selectedItem.fecha_de_fin = null

          await this.$store.dispatch(
            'app-trabajo/addTrabajo',
            this.selectedItem,
          )
          this.selectedEstado = null
          this.openEstadoModal = false
          this.showErrorEstado = ''
          this.selectedEstado = null
          this.openEstadoModal = false
          this.showErrorEstado = ''
        }
      } else if (this.selectedEstado === 'terminado') {
        if (
          this.selectedItem.fecha_de_fin
          && this.selectedItem.jefe_equipo
          && this.selectedItem.fecha_de_inicio
          && this.selectedItem.numero_empleados
          && this.selectedItem.asignado_a
          && this.selectedItem.materiales.length > 0
        ) {
          this.selectedItem.estado = this.selectedEstado
          await this.$store.dispatch('app-trabajo/updateEstado', {
            id: this.selectedItem.id,
            estado: this.selectedEstado,
          })
          this.selectedEstado = null
          this.openEstadoModal = false
          this.showErrorEstado = ''
        } else {
          this.showErrorEstado = 'Faltan campos por rellenar!'
          if (!this.selectedItem.fecha_de_fin) this.missingFields.push('Fecha de fin')
          if (!this.selectedItem.fecha_de_inicio) this.missingFields.push('Fecha de inicio')
          if (!this.selectedItem.jefe_equipo) this.missingFields.push('Jefe de equipo')
          if (!this.selectedItem.numero_empleados) this.missingFields.push('Numero empleados')
          if (!this.selectedItem.asignado_a) this.missingFields.push('Asignado a')
          if (this.selectedItem.materiales < 1) this.missingFields.push('Materiales')
        }
      } else if (this.selectedEstado === 'asignado') {
        if (this.selectedItem.asignado_a) {
          this.selectedItem.estado = this.selectedEstado
          await this.$store.dispatch('app-trabajo/updateEstado', {
            id: this.selectedItem.id,
            estado: this.selectedEstado,
          })
          this.selectedEstado = null
          this.openEstadoModal = false
          this.showErrorEstado = ''
        } else {
          this.showErrorEstado = 'Faltan campos por rellenar!'
          this.missingFields.push('Asignado a')
        }
      } else if (this.selectedEstado === 'incidencia') {
        if (this.selectedItem.incidencias) {
          this.selectedItem.estado = this.selectedEstado
          await this.$store.dispatch('app-trabajo/updateEstado', {
            id: this.selectedItem.id,
            estado: this.selectedEstado,
          })
          this.selectedEstado = null
          this.openEstadoModal = false
          this.showErrorEstado = ''
        } else {
          this.showErrorEstado = 'Faltan campos por rellenar!'
          this.missingFields.push('Incidencias')
        }
      } else {
        await this.$store.dispatch('app-trabajo/updateEstado', {
          id: this.selectedItem.id,
          estado: this.selectedEstado,
        })
        this.selectedEstado = null
        this.openEstadoModal = false
        this.showErrorEstado = ''
      }

      this.$emit('refetchStats')
      this.refetchData()
    },
    calculateDuration(initDate, endDate) {
      const aDayInMilliseconds = 24 * 60 * 60 * 1000

      const firstDate = new Date(initDate)
      const lastDate = new Date(endDate)

      const differenceInMilliseconds = Math.abs(lastDate - firstDate)

      const differenceInDays = Math.floor(
        differenceInMilliseconds / aDayInMilliseconds,
      )
      return differenceInDays
    },
    editTrabajo(id) {
      this.$router.push({
        name: 'trabajo-edit',
        params: {
          id,
        },
      })
    },
    deleteTrabajo(id) {
      this.$store.dispatch('app-trabajo/deleteTrabajo', id).then(() => {
        this.deleteModal = false
        this.selectedItem = {}
        this.refetchData()
        this.$emit('refetchStats')
      })
    },
    peticionarioLabel(peticionario) {
      switch (peticionario) {
        case 'alcalde':
          return 'Alcalde / Alcaldesa'
        case 'consejeros_delegados':
          return 'Consejeros Delegados'
        case 'gerente':
          return 'Gerente'
        case 'gecor':
          return 'GECOR'
        case 'otros':
          return 'Otros'
        default:
          return peticionario
      }
    },
    estadoLabel(estado) {
      switch (estado) {
        case 'terminado':
          return 'TERMINADO'
        case 'cancelado':
          return 'CANCELADO'
        case 'no_asignado':
          return 'NO ASIGNADO'
        case 'incidencia':
          return 'INCIDENCIA'
        case 'asignado':
          return 'ASIGNADO'
        case 'en_revision':
          return 'EN REVISION'
        case 'iniciado':
          return 'INICIADO'
        case 'rehacer':
          return 'REHACER'
      }
    },
    estadoColor(estado) {
      switch (estado) {
        case 'terminado':
          return 'background-color: #30d146'
        case 'cancelado':
          return 'background-color: #ff294d'
        case 'no_asignado':
          return 'background-color: #fc8803'
        case 'incidencia':
          return 'background-color: #fc3297'
        case 'asignado':
          return 'background-color: #fc3297'
        case 'en_revision':
          return 'background-color: #e023fc'
        case 'iniciado':
          return 'background-color: #03c6fc'
        case 'rehacer':
          return 'background-color: #fc8803'
      }
    },
    convertDate(date) {
      if (date != null) {
        const data = new Date(date)
        const dia = data.getDate().toString()
        const diaF = dia.length == 1 ? `0${dia}` : dia
        const mes = (data.getMonth() + 1).toString()
        const mesF = mes.length == 1 ? `0${mes}` : mes
        const anoF = data.getFullYear()
        const finalDate = `${diaF}-${mesF}-${anoF}`
        return finalDate
      }
      return 'undefined'
    },
  },
  setup() {
    const TRABAJO_APP_STORE_MODULE_NAME = 'app-trabajo'
    const ZONAS_APP_STORE_MODULE_NAME = 'app-zona'

    // Register module
    if (!store.hasModule(TRABAJO_APP_STORE_MODULE_NAME)) store.registerModule(TRABAJO_APP_STORE_MODULE_NAME, trabajoStoreModule)

    if (!store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONAS_APP_STORE_MODULE_NAME, zonaStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRABAJO_APP_STORE_MODULE_NAME)) store.unregisterModule(TRABAJO_APP_STORE_MODULE_NAME)

      if (store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) store.unregisterModule(ZONAS_APP_STORE_MODULE_NAME)
    })

    const zonasList = ref([])
    const { listZonas } = useZonasList()

    listZonas().then(data => {
      zonasList.value = data.filter(zona => !zona.parent)
    })

    const {
      fetchTrabajo,
      tableColumns,
      perPage,
      currentPage,
      totalTrabajo,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTrabajoListTable,

      refetchData,
      filterFechaInicio,
      filterFechaFin,
      filterData,
      isFiltering,
      clearFilter,
      filterZona,
      filterEstado,
    } = useTrabajoList()

    return {
      fetchTrabajo,
      tableColumns,
      perPage,
      currentPage,
      totalTrabajo,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTrabajoListTable,

      refetchData,
      filterFechaInicio,
      filterFechaFin,
      filterData,
      isFiltering,
      clearFilter,
      filterZona,
      filterEstado,
      listZonas,
      zonasList,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
